@import "variables";

// Variables

$bleach-light-gray: #f6f6f6;
$bleach-dark-gray: #343a40;
$bleach-light-gray-dark: #fbf7f4;

$primary: $bleach-blue;
$primary-rgb: $bleach-blue-rgb;
$secondary: $bleach-scarpa;
$secondary-rgb: $bleach-scarpa-rgb;

// 4. Base Typography
// ------------------

$anchor-color: #{var(--link)};
$anchor-color-hover: #{var(--anchor-hover)};

// 20. Forms
// ---------

$input-placeholder-color: $bleach-dark-blue;
$input-border: 1px solid #6e6e6e;

// 53. Title Bar
// -------------

$titlebar-background: $white;
