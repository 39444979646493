$footer-bg: var(--footer);
$footer-color: $bleach-light-blue;
$footer-link-color: #fff;

//Main footer
.main-footer{
  background-color: $footer-bg;
  position: relative;
  padding: 1rem 0;
  min-height: 76px;
}

.main-footer,
.mini-footer{
  background-color: $footer-bg;
  color: $footer-color;

  a{
    color: $footer-link-color;
    font-weight: bold;

    &:hover,
    &:focus{
      color: #fff;
    }
  }
}

.main-footer__badge{
  display: inline-block;
  width: 140px;

  @include breakpoint(large){
    position: static;
  }

  @media all and (max-width: 640px) {
    display: block;
      margin-left: auto;
      margin-right: auto;
  }
}
