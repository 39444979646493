.button--mpassid{
  &.button--social--mini{
    .button--social__icon{
      padding: 0 .4rem;
    }
  }

  .button--social__icon{
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 3rem;
    padding: 0 .3rem;

    svg.mpass,
    svg.mpassc{
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    svg.mpass{
      fill: inherit;
    }
  }
}
