// Base

$white: #fff;
$black: #000;

// Grays

$bleach-light-gray: #f6f6f6;
$bleach-dark-gray: #343a40;
$bleach-light-gray-dark: #fbf7f4;

// Bleach, palette: https://coolors.co/f4f1de-e07a5f-3d405b-81b29a-f2cc8f
$bleach-white-rgb: 244,241,22;
$bleach-white: #f4f1de;
$bleach-red-rgb: 224,122,95;
$bleach-red: #e07A5f;
$bleach-blue-rgb: 61,64,91;
$bleach-blue: #3d405b;
$bleach-green-rgb: 129,178,154;
$bleach-green: #81b29a;
$bleach-yellow-rgb: 242,204,143;
$bleach-yellow: #f2cc8f;

// Bleach blues

$bleach-dark-blue-rgb: 1,42,74;
$bleach-dark-blue: #012a4a;
$bleach-dark-blue-alternative-rgb: 3,4,94;
$bleach-dark-blue-alternative: #0077b6;
$bleach-light-blue-rgb: 237, 242, 251;
$bleach-light-blue: #edf2fb;
$bleach-light-blue-alternative-rgb: 226,234,252;
$bleach-light-blue-alternative: #e2eafc;
$bleach-scarpa-rgb: 86,82,100;
$bleach-scarpa: #565264;

// Variables

$link: #206cff !default;
$link-rgb: 32, 108, 255 !default;
$footer: $bleach-scarpa !default;
$footer-rgb: $bleach-scarpa-rgb !default;
